import React from 'react';
import { Box, Typography, Container, CssBaseline } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import { styled } from '@mui/material/styles';

import ThemeProviderWrapper from '../theme/themeProvider';
import StaticPageFooter from '../layout/staticPageFooter';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Status404() {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "404.svg"}) {
        publicURL
      }
    }
  `);

  return (
    <ThemeProviderWrapper>
      <CssBaseline />
      <title>Status - 404</title>
      <MainContent>
        <Container maxWidth="md" sx={{ mt:5 }} >
          <Box textAlign="center">
            <img src={data.file.publicURL} alt="waste image" height={180} />
            <Typography variant="h2" sx={{ my: 2 }}>
              Sorry the page you were looking for doesn't exist.
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{ mb: 4 }}
            >
              Please click the link below to return to the homepage
            </Typography>
          </Box>
          <StaticPageFooter />
        </Container>
      </MainContent>
    </ThemeProviderWrapper>
  );
}

export default Status404;
