import React from "react";
import { Container, Box, Typography, Button, Divider } from "@mui/material";
import { Link } from 'gatsby';

const StaticPageFooter = () => {

    return (
        <Container>
            <Container maxWidth="sm" sx={{textAlign:'center'}}>
                <Link to="/" style={{ textDecoration:'none' }}>
                    <Button href="/overview" variant="contained">
                        Back to homepage
                    </Button>
                </Link>
            </Container>
            <Divider sx={{ my: 4 }} />
            <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            >
                <Box>
                    <Typography
                    component="span"
                    variant="subtitle1"
                    color="text.primary"
                    >
                    Digibin &copy; Waste Quote Expert Limited
                    </Typography>
                </Box>
            </Box>
        </Container>
    );

}

export default StaticPageFooter;